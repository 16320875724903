* {
  box-sizing: border-box;
}

html {
  height: 97%;
}

// div#root {
//     margin: 9px;
// }

body {
  font-family: 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: $fontSize;
  color: $textColor;
  background-color: $bodyBgColor;
  margin: 0;
  padding: 0;
  min-height: 100%;
}

a {
  text-decoration: none;
}

.layout-wrapper {
  padding: 0;

  .layout-topbar {
    position: fixed;
    height: 50px;
    padding: 0.7em 1.5em 0em 1.5em;
    color: #ffffff;
    z-index: 999;
    right: 0;
    @include linear-gradient-left($topbarLeftBgColor, $topbarRightBgColor);
    @include transition(left $transitionDuration);

    .layout-topbar-icons {
      float: right;
      display: block;
      -moz-animation-duration: 0.5s;
      -webkit-animation-duration: 0.5s;
      animation-duration: 0.5s;

      a {
        position: relative;
        color: $topbarItemColor;
        margin-left: 20px;
        display: inline-block;
        text-decoration: none;
        @include transition(color $transitionDuration);

        &:hover {
          color: $topbarItemHoverColor;
        }

        span {
          &.layout-topbar-icon {
            font-size: 2em;
          }

          &.layout-topbar-item-text {
            font-size: 20px;
            display: none;
          }

          &.layout-topbar-badge {
            position: absolute;
            font-size: 10px;
            right: -5px;
            top: -5px;
            width: 16px;
            height: 16px;
            text-align: center;
            line-height: 16px;
            color: $topbarItemBadgeColor;
            background-color: $topbarItemBadgeBgColor;
            @include border-radius(50%);
          }
        }
      }

      .layout-topbar-search {
        padding: 0;
        position: relative;
        display: inline-block;
        top: -4px;

        input {
          display: inline-block;
          border: 0 none;
          font-size: $fontSize;
          background: transparent;
          border-bottom: 2px solid $topbarSearchInputBorderBottomColor;
          outline: 0 none;
          -webkit-box-shadow: none;
          box-shadow: none;
          color: $topbarSearchInputColor;
          width: 100px;
          padding: 1px 20px 1px 1px;
          margin: 0px;
          @include border-radius(2px);

          &::-webkit-input-placeholder {
            color: $topbarSearchInputColor;
            opacity: 0.7;
            @include transition(opacity $transitionDuration);
          }
          &:-moz-placeholder {
            color: $topbarSearchInputColor;
            opacity: 0.7;
            @include transition(opacity $transitionDuration);
          }
          &::-moz-placeholder {
            color: $topbarSearchInputColor;
            opacity: 0.7;
            @include transition(opacity $transitionDuration);
          }
          &:-ms-input-placeholder {
            color: $topbarSearchInputColor;
            opacity: 0.7;
            @include transition(opacity $transitionDuration);
          }
        }

        .layout-topbar-search-icon {
          font-size: 18px;
          position: absolute;
          top: -1px;
          right: 0px;
        }

        &:hover {
          input {
            border-bottom-color: $topbarItemHoverColor;
            &::-webkit-input-placeholder {
              opacity: 1;
            }
            &:-moz-placeholder {
              opacity: 1;
            }
            &::-moz-placeholder {
              opacity: 1;
            }
            &:-ms-input-placeholder {
              opacity: 1;
            }
          }

          .layout-topbar-search-icon {
            color: $topbarItemHoverColor;
          }
        }
      }
    }

    .layout-menu-button {
      cursor: pointer;
      display: inline-block;
      text-decoration: none;
      color: $topbarItemColor;
      @include transition(color $transitionDuration);

      span {
        font-size: 2em;
      }

      &:hover {
        color: $topbarItemHoverColor;
      }
    }

    a {
      cursor: pointer;
    }
  }

  .p-scrollpanel {
    background: transparent;
    border-radius: 0;
    border: none;

    .p-scrollpanel-content {
      overflow: scroll;
    }

    .p-scrollpanel-bar {
      background: $scrollPanelBgColor;
      @include opacity(0.3);
    }

    .p-scrollpanel-hidden {
      display: block;
      visibility: hidden;
    }

    .layout-sidebar-scroll-content {
      width: calc(100% + 18px);
      padding-bottom: 120px;
    }
  }

  .layout-sidebar {
    position: fixed;
    width: 250px;
    height: 100%;
    z-index: 999;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    @include transition(left $transitionDuration);
    @include linear-gradient($menuBgColorFirst, $menuBgColorLast);
    @include shadow(0 0 6px 0 rgba(0, 0, 0, 0.16));

    .layout-logo {
      text-align: center;
      margin-top: 24px;

      img {
        width: 150px;
        height: 80px;
      }
    }

    .profile {
      text-align: center;
      padding: 20px 0;

      img {
        width: 56px;
        margin: 10px;
      }

      .profile-link {
        cursor: pointer;
        color: $menuitemColor;
        display: inline-block;
        margin-bottom: 10px;
        @include transition(color $transitionDuration);

        i {
          display: inline-block;
          font-size: 16px;
        }

        &:hover {
          color: $menuitemHoverColor;
        }
      }

      > ul {
        overflow: hidden;
        background-color: $menuitemActiveBgColor;
        text-align: left;
        max-height: 0;
        @include transition-duration($transitionDuration);
        @include transition-timing-function(cubic-bezier(0.86, 0, 0.07, 1));

        &.profile-expanded {
          max-height: 500px;
        }

        li {
          a {
            border: 0 none;
          }

          &:last-child {
            > a {
              border: 0 none;
            }
          }
        }
      }
    }

    ul {
      list-style-type: none;
      margin: 0;
      padding: 0;

      li {
        a {
          cursor: pointer;
          position: relative;
          color: $menuitemColor;
          text-decoration: none;
          font-size: $fontSize;
          padding: 1em 15px 1em 1em;
          display: block;
          border-top: 1px solid $menuitemBorderColor;

          i {
            font-size: 18px;
            vertical-align: middle;
          }

          span {
            margin-left: 0.25em;
            vertical-align: middle;
          }

          .menuitem-toggle-icon {
            float: right;
          }

          &:hover {
            color: $menuitemHoverColor;
          }
        }

        &.active-menuitem {
          > a {
            background-color: $menuitemActiveBgColor;
            color: $menuitemActiveColor;

            .menuitem-toggle-icon {
              @include icon-override('\e933');
            }
          }

          > ul {
            max-height: 5000px;
          }
        }

        ul {
          background-color: $menuitemActiveBgColor;
          overflow: hidden;
          padding-left: 1.5em;
          max-height: 0;
          @include transition-property(max-height);
          @include transition-duration(0.4s);
          @include transition-timing-function(cubic-bezier(0.86, 0, 0.07, 1));

          li {
            a {
              cursor: pointer;
              padding: 0.75em 33px 0.75em 0.75em;
              font-size: $submenuFontSize;
              border-top: 0 none;
            }

            &:last-child {
              > a {
                border-bottom: 0 none;
              }
            }
          }
        }

        &:last-child {
          > a {
            border-bottom: 1px solid $menuitemBorderColor;
          }
        }
      }

      .menuitem-toggle-icon {
        float: right;
        margin-top: 2px;
      }

      .menuitem-badge {
        margin-top: 3px;
        font-size: 10px;
        float: right;
        width: 16px;
        height: 16px;
        line-height: 16px;
        text-align: center;
        color: $menuitemBadgeColor;
        background-color: $menuitemBadgeBgColor;
        @include border-radius(50%);
      }
    }

    &.layout-sidebar-dark {
      @include linear-gradient($menuDarkBgColorFirst, $menuDarkBgColorLast);

      .layout-logo {
      }

      .profile {
        > a {
          color: $menuitemDarkColor;

          &:hover {
            color: $menuitemDarkHoverColor;
          }
        }

        > ul {
          background-color: $menuitemDarkActiveBgColor;

          li {
            a {
              border: 0 none;
            }
          }
        }
      }

      ul {
        li {
          a {
            color: $menuitemDarkColor;
            border-top: 1px solid $menuitemDarkBorderColor;

            &:hover {
              color: $menuitemDarkHoverColor;
            }
          }

          &.active-menuitem {
            > a {
              background-color: $menuitemDarkActiveBgColor;
              color: $menuitemDarkActiveColor;
            }
          }

          ul {
            background-color: $menuitemDarkActiveBgColor;

            li {
              a {
                border: none;
              }
            }
          }

          &:last-child {
            > a {
              border-bottom: 1px solid $menuitemDarkBorderColor;
            }
          }
        }
      }
    }
  }

  .layout-main {
    @include transition(margin-left $transitionDuration);
    padding: 50px 16px 16px 16px;

    .layout-content {
      margin-left: 2px;
      margin-right: 2px;
      // padding-top: 15px;
    }

    .layout-content-webspeed {
      @extend .layout-content;
      background-color: #ffffff;
    }

    .layout-content-iframe {
      height: 90vh;
      width: 100%;
    }
  }

  .layout-footer {
    @include transition(margin-left $transitionDuration);
    background-color: $footerBgColor;
    padding: 1em 2em;

    img {
      vertical-align: middle;
    }

    .footer-text {
      vertical-align: middle;
    }
  }
}

/* Responsive */
@media (min-width: 1025px) {
  .layout-wrapper {
    &.layout-overlay {
      .layout-sidebar {
        left: -250px;
      }

      .layout-topbar {
        left: 0;
      }

      .layout-main,
      .layout-footer {
        margin-left: 0;
      }

      &.layout-overlay-sidebar-active {
        .layout-sidebar {
          left: 0;
        }

        .layout-topbar {
          left: 250px;
        }
      }
    }

    &.layout-static {
      .layout-sidebar {
        left: 0;
      }

      .layout-topbar {
        left: 250px;
      }

      .layout-main,
      .layout-footer {
        margin-left: 240px;
      }

      &.layout-static-sidebar-inactive {
        .layout-sidebar {
          left: -250px;
        }

        .layout-topbar {
          left: 0;
        }

        .layout-main,
        .layout-footer {
          margin-left: 0;
        }
      }
    }
  }
}

@media (max-width: 1024px) {
  .layout-wrapper {
    .layout-topbar {
      left: 0;
    }

    .layout-main,
    .layout-footer {
      margin-left: 0;
    }

    .layout-main {
      .layout-content {
        margin-left: 0px;
      }
    }

    .layout-sidebar {
      left: -250px;
      margin-top: 50px;
    }

    .layout-mask {
      display: none;
      position: fixed;
      width: 100%;
      height: 100%;
      top: 50px;
      left: 0;
      z-index: 998;
      background-color: $maskBgColor;
      @include opacity(0.7);
    }

    &.layout-mobile-sidebar-active {
      .layout-sidebar {
        left: -0;
      }

      .layout-mask {
        display: block;
      }
    }
  }

  .body-overflow-hidden {
    overflow: hidden;
  }
}

@media (max-height: 800px) {
  .layout-wrapper {
    .layout-main {
      .layout-content-iframe {
        height: 88vh;
      }
    }
  }
}

@media (max-height: 700px) {
  .layout-wrapper {
    .layout-main {
      .layout-content-iframe {
        height: 86vh;
      }
    }
  }
}
