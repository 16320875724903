.exception-panel {

    margin-top: 20px;

    .p-message {
        width: 100%;

        .pi {
            font-size: 3em;
        }
        
        .p-message-text {
            font-size: 1em;
            font-weight: bold;
        }
    }
}
